<template>
	<div ref="formContainer">
		<AddHeaderTitle :Header="Header" />
		<AppHeaderInfoAdminList :infos="infos" />

		<b-table striped hover :items="records" :fields="fields">
			<template #cell(default)="row">
				<span v-if="row.item.default == 1">
					<b-avatar variant="light-success" size="34">
						<feather-icon icon="CheckCircleIcon" size="25" />
					</b-avatar>
				</span>
				<span v-else>
					<b-avatar variant="light-warning" size="34">
						<feather-icon icon="XCircleIcon" size="25" />
					</b-avatar>
				</span>
			</template>
			<template #cell(startTime)="row">
				{{ formatTime(row.item.startTime) }}
			</template>
			<template #cell(endTime)="row">
				{{ formatTime(row.item.endTime) }}
			</template>
			<template #cell(duration)="row">
				{{ relativeTime(row.item.startTime, row.item.endTime) }}
			</template>
			<template #cell(status)="row">
				<span v-if="row.item.status === 1">
					<b-badge variant="primary" class="badge-glow">
						Accessible
					</b-badge>
				</span>
				<span v-else-if="row.item.status === 10">
					<b-progress max="100" class="progress-bar-info">
						<b-progress-bar
							:value="pct"
							:label="`${Number.parseFloat(pct).toFixed(2)}%`"
							variant="info"
						/>
					</b-progress>
				</span>
				<span v-else> Non converti </span>
			</template>

			<template #cell(action)="list">
				<b-dropdown variant="link" toggle-class="p-0" no-caret right>
					<template #button-content>
						<feather-icon
							icon="MoreVerticalIcon"
							size="16"
							class="align-middle text-body"
						/>
					</template>

					<b-dropdown-item
						variant="flat-primary"
						size="sm"
						:disabled="defaultDisabled(list.item.default, list.item.status)"
						@click="switchToDefault(list.item)"
					>
						<feather-icon icon="AwardIcon" />
						Passer en default replay
					</b-dropdown-item>
					<b-dropdown-item
						@click="goToVideo(list.item.url)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="SendIcon" />
						Accéder à la vidéo
					</b-dropdown-item>
					<b-dropdown-item
						@click="deleteVideo(list.item.recordId)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="TrashIcon" />
						Supprimer la vidéo
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</b-table>
	</div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import {
	BTable,
	BAvatar,
	BDropdown,
	BDropdownItem,
	BBadge,
	BProgress,
	BProgressBar,
} from "bootstrap-vue";
import { url } from "@/services/axios";
import { Admin } from "@/mixins/admin.js";
import { utcToZonedTime, format } from "date-fns-tz";
import { io } from "socket.io-client";
export default {
	name: "viewers",

	components: {
		BTable,
		BAvatar,
		BDropdown,
		BDropdownItem,
		BBadge,
		BProgress,
		BProgressBar,
		AddHeaderTitle,
		AppHeaderInfoAdminList,
	},

	data() {
		return {
			Header: {
				config: "Admin",
				listLink: "/admin/webinar/list",
				list: "Webinar-liste",
				detailLink: "",
				detail: "Enregistrement",
			},
			infos: {
				title: "Liste des enregistrements",
				content: "Liste des enregistrements liés à un webinaire",
			},
			url: url,
			viewerData: {
				number_ceu: "",
				number_cfc: "",
				firstname: "",
				lastname: "",
				email: "",
				id: 0,
				presence_percentage: "",
			},
			speaker: {},
			statusArray: [
				{
					status: true,
					badge: "warning",
					text: "Actif",
				},
				{
					status: false,
					badge: "danger",
					text: "Inactif",
				},
			],
			modalEditViewer: false,
			list: [],
			// Note 'isActive' is left out and will not appear in the rendered table
			fields: [
				{
					key: "default",
					label: "replay default",
					sortable: true,
				},
				{
					key: "startTime",
					label: "Date de début",
					sortable: true,
				},
				{
					key: "endTime",
					label: "Date de fin",
					sortable: true,
				},
				{
					key: "duration",
					label: "Durée",
					sortable: true,
				},
				{
					key: "participants",
					label: "Participants",
					sortable: true,
				},

				{
					key: "status",
					label: "Statut",
					sortable: true,
				},
				{
					key: "Action",
					label: "Action",
					sortable: false,
				},
			],
			units: [
				{ label: "année", seconds: 31536000 },
				{ label: "mois", seconds: 2592000 },
				{ label: "semaine", seconds: 604800 },
				{ label: "jour", seconds: 86400 },
				{ label: "heure", seconds: 3600 },
				{ label: "minute", seconds: 60 },
				{ label: "seconde", seconds: 1 },
			],
			pct: 0,
			busy: false,
			processing: false,
			idWebinar: this.$route.params.id,
			viewers: [],
			records: [],
			adminRole: this.$store.state.user.user.role,
			socket: null,
		};
	},

	mixins: [Admin],

	mounted() {
		this.loadRecords();
	},
	created() {
		this.socket = io("https://ws.aba-live.fr/", { withCredentials: false });
		this.socket.on("pct_conversion", (data) => {
			const [record] = this.records.filter(
				(el) => el.recordId == data.recordId
			);
			if (record) {
				const currentIndex = this.records.indexOf(record);
				this.records[currentIndex].status = 10;
				this.pct = data.pct;
			} else {
				this.pct = 0;
			}
		});

		this.socket.on("pct_conversion_done", (data) => {
			const [record] = this.records.filter(
				(el) => el.recordId == data.recordId
			);
			if (record) {
				const currentIndex = this.records.indexOf(record);
				this.records[currentIndex].status = 1;
				this.pct = 0;
			}
		});
	},
	computed: {},
	methods: {
		defaultDisabled(defaultVideo, status) {
			if (defaultVideo == 1) return true;
			if (status === 10) return true;
			return false;
		},
		formatTime(dateTime) {
			const date = new Date(dateTime);
			const timeZone = "Europe/Paris";
			const zonedDate = utcToZonedTime(date, timeZone);
			const patternTime = "dd/MM/yyyy HH:mm";
			return format(zonedDate, patternTime, {
				timeZone: "Europe/Paris",
			});
		},
		relativeTime(start, end) {
			const time = Math.floor(
				(new Date(end).valueOf() - new Date(start).valueOf()) / 1000
			);
			const { interval, unit } = this.calculateTimeDifference(time);
			const suffix = interval === 1 && unit != "mois" ? "" : "s";
			return `${interval} ${unit}${suffix}`;
		},

		calculateTimeDifference(time) {
			for (const { label, seconds } of this.units) {
				const interval = Math.floor(time / seconds);
				if (interval >= 1) {
					return {
						interval: interval,
						unit: label,
					};
				}
			}
			return {
				interval: 0,
				unit: "",
			};
		},
		async loadRecords() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.getRecordsByWebinarId(this.idWebinar);
				if (response.status === 1) {
					this.records = response.datas;
				} else {
					this.$sweetError("LOAD-VIEWER");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("LOAD-VIEWER-1");
			}
		},

		async deleteVideo(id) {
			try {
				let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: true,
				});
				//TODO alert
				const response = await this.deleteOneRecord(id);
				if (response.status === 1) {
					this.records = this.records.filter(
						(record) => record.recordId !== id
					);
					loader.hide();
					this.$sweetNotif("video deleted");
				} else {
					this.$sweetError("Delete failed with code 1");
					loader.hide();
				}
			} catch (error) {
				loader.hide();
				this.$sweetError("Delete failed with code 2");
			}
		},
		async switchToDefault(item) {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			const currentIndex = this.records.indexOf(item);
			try {
				const response = await this.updateRecordtoDefault(
					this.idWebinar,
					item.id
				);

				if (response.status === 1) {
					this.records = this.records.map((el) => {
						return {
							...el,
							default: false,
						};
					});
					this.records[currentIndex].default = true;
					this.$sweetNotif("Video updated to default flag");
				} else {
					this.$sweetError("update default record");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("UPDATE-DEFAULT-1");
			}
		},

		goToVideo(url) {
			window.open(url, "_blank");
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
